import React, { useState } from "react"
import styled from "styled-components"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { device } from "../../theme"
import { handleCharacters } from "../../utils/handleCharacters"
import { Link } from "gatsby"

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 16px;
  height: 470px;
  @media ${device.tablet} {
    height: auto;
  }
`

const Container = styled.div`
  height: 470px;
  background: #fcfcfc;
  border-radius: 4px;
  border-radius: 12px;
  width: calc(25% - 16px);

  @media ${device.tablet} {
    width: calc(33.333% - 16px) !important;
  }

  @media ${device.phone} {
    width: calc(50% - 16px) !important;
    padding: 6px !important;
    height: 330px;
    box-shadow: 0 0 12px 1px #f2f2f2;
  }

  &:hover {
    box-shadow: 0 0 12px 6px #f0f0f0;
    // .info-container {
    //   transform: translateY(-6px);
    // }

    .buy-link {
      transform: translateY(6px);
    }

    .bottom-line {
      opacity: 1;
    }

    .product-image {
      // transform: scale(1.02, 1.02);
    }
  }
`

const ImageContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 75%;
  // overflow: hidden;
  transition: transform 0.2s ease-in;
  cursor: pointer;

  &.half {
    height: 60%;
  }

  @media ${device.phone} {
    height: 70%;
    align-items: flex-start;
  }
`

const Image = styled(LazyLoadImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  margin-bottom: 0;
  transition: 0.2s linear;
  text-indent: -9999px;
  border-radius: 12px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`

const InfoContainer = styled.div`
  height: 25%;
  padding: 22px 12px 12px;
  position: relative;
  transition: transform 0.2s ease-in;

  &.half {
    height: 40%;
  }

  @media ${device.phone} {
    height: 30%;
    padding: 22px 12px;
  }
`

const Title = styled.h3`
  font-size: 0.8rem;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  @media ${device.phone} {
    height: 18px;
  }
`

const Brand = styled.p`
  margin: 6px 0;
  font-size: 0.75rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${device.phone} {
    margin: 0;
    max-width: 100%;
    height: 20px;
  }
`

const Price = styled.p`
  margin: 0;
  font-size: 0.9rem;
  font-weight: bold;
`

const BottomLine = styled.div`
  height: 6px;
  width: 100%;
  display: none;
  opacity: 0.6;
  transition: opacity 0.2s ease-in-out;
  background: ${props => props.theme.colors.green};

  @media ${device.tablet} {
    display: none;
  }
`

const StyledLink = styled(Link)`
  color: inherit;
  &:hover {
    color: inherit;
  }
`

const BuyLink = styled.a`
  color: inherit;
  &:hover {
    color: inherit;
  }
`

const Discount = styled.label`
  background: ${props => props.theme.colors.green};
  color: white;
  font-weight: bold;
  padding: 4px 6px;
  border-radius: 12px;
  border-bottom-right-radius: 0;
  border-top-left-radius: 0;
  font-size: 16px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  margin: 0 auto;
  text-decoration: line-through;
`

function Offers({ gender }) {
  const [offers, setOffers] = useState(null)

  React.useEffect(() => {
    const url = `https://static8-api.herokuapp.com/lineout/offers`
    fetch(url)
      .then(res => res.json())
      .then(offers => setOffers(offers))
      .catch(err => console.log(err))
  }, [])

  const handleImageSize = store => {
    if (store === "Nike" || store === "Kaufmann" || store === "Quint") {
      return " half"
    }

    return ""
  }

  return (
    <>
      <h1>
        {gender === "men"
          ? "Største tilbud - herretøj"
          : "Største tilbud - dametøj"}
      </h1>
      <Row>
        {!offers
          ? null
          : offers[gender === "men" ? "discountsMen" : "discountsWomen"].map(
              ({
                productStore,
                productImage,
                productName,
                productStoreKey,
                productAffiliate,
                productId,
                productUrl,
                productBrand,
                productPrice,
                productOldPrice,
                productPath,
                categoryPage,
              }) => (
                <Container onClick={() => window.open(productUrl)}>
                  <ImageContainer>
                    <Image
                      className="product-image"
                      src={productImage}
                      alt={productName}
                    />
                    <Discount>{productOldPrice} kr</Discount>
                  </ImageContainer>
                  <InfoContainer
                    className={`info-container${handleImageSize(productStore)}`}
                  >
                    {productAffiliate === "partner-ads" ? (
                      <StyledLink
                        exact
                        to={`/${productPath}/${categoryPage}/${productStoreKey}-${productId}-${productName
                          .replace(/\s/g, "-")
                          .replace(/[^a-å0-9-]/gi, "")
                          .toLowerCase()}`}
                      >
                        <Title>{productName}</Title>
                      </StyledLink>
                    ) : (
                      <BuyLink href={productUrl} target="_blank" rel="noopener">
                        <Title className="product-title">{productName}</Title>
                      </BuyLink>
                    )}
                    <Brand>{handleCharacters(productBrand)}</Brand>
                    <Brand>{productStore}</Brand>
                    <Price>{productPrice} kr</Price>
                  </InfoContainer>
                  <BottomLine className="bottom-line" />
                </Container>
              )
            )}
      </Row>
    </>
  )
}

export default Offers
