import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"
import { device } from "../../theme"
import tshirt from "../../images/category-tshirt.jpg"

const Container = styled.div`
  position: relative;
  height: 360px;
  background: url(${props => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 0 12px 1px #f2f2f2;
  transition: 0.3s linear;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .category-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    transition: 0.2s linear;
  }

  &:hover {
    box-shadow: 0 0 12px 6px #f0f0f0;
    .category-layer {
      background: rgba(0, 0, 0, 0.3);
    }

    .category-title {
      transform: translateY(-4px);
    }

    .read-more {
      transform: translateY(-18px);
      &:after {
        width: 100%;
        left: 0;
        margin-top: 4px;
      }
    }
  }

  @media ${device.phone} {
    height: 230px;
  }
`

const Title = styled.h3`
  color: #fff;
  z-index: 1;
  transition: 0.2s linear;
  font-size: 1.5rem;
`

const ReadMore = styled.p`
  color: #fff;
  z-index: 1;
  transition: 0.3s ease-out;
  font-size: 1rem;

  &:after {
    background: none repeat scroll 0 0 transparent;
    bottom: 0;
    content: "";
    display: block;
    height: 2px;
    margin-top: 4px;
    left: 50%;
    position: absolute;
    background: ${props => props.theme.colors.green};
    transition: width 0.3s ease 0s, left 0.3s ease 0s;
    width: 0;
  }
`

function CategoryCard({ category, image, url }) {
  return (
    <Link to={url}>
      <Container src={image}>
        <div className="category-layer" />
        <Title className="category-title">{category}</Title>
        <ReadMore className="read-more">Gå til udvalg</ReadMore>
      </Container>
    </Link>
  )
}

export default CategoryCard
