import { Link } from "gatsby"
import React, { useContext, useEffect } from "react"
import { Grid } from "semantic-ui-react"
import styled from "styled-components"
import CategoryCard from "../../components/CategoryCard"
import Layout from "../../components/Layout"
import Products from "../../components/Products"
import Sidebar from "../../components/Sidebar"
import { GenderContext } from "../../providers/GenderProvider"
import { device } from "../../theme"
import tshirt from "../../images/womentshirt.jpg"
import accessories from "../../images/womenaccessories.jpg"
import bag from "../../images/womenbags.jpg"
import hoodie from "../../images/womenhoodie.jpg"
import jacket from "../../images/womenjacket.jpg"
import jeans from "../../images/womenjeans.jpg"
import shoes from "../../images/womenshoes.jpg"
import sport from "../../images/category-sport.jpg"
import shirt from "../../images/womenshirt.jpg"
import underwear from "../../images/womenunderwear.jpg"
import Breadcrumbs from "../../components/Breadcrumbs"
import Offers from "../../components/Offers"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;

  @media ${device.tablet} {
  }
`

const Title = styled.h1`
  font-size: 4rem;
  margin: 32px 32px 0;
  color: #4a4a4a;
`

const SubTitle = styled.h2`
  font-size: 1rem;
  margin: 20px 52px;
  color: #828282;
`

const StyledGrid = styled(Grid)`
  position: relative;
  overflow: hidden;
  justify-content: space-between;
  width: 100%;
  margin-top: 60px !important;

  &::after {
    content: "";
    flex: auto;
  }

  padding-bottom: 110px !important;
  @media ${device.tablet} {
    max-height: ${props => props.height + 720}px;
  }

  @media ${device.phone} {
    max-height: ${props => props.height + 70}px;
  }
`

const Column = styled(Grid.Column)`
  cursor: pointer;
  &.five.wide.computer.eight.wide.mobile.five.wide.tablet.column {
    width: 20% !important;
    padding: 6px !important;

    // &:nth-child(even) {
    //   padding: 32px 6px 0;
    // }

    // &:nth-child(odd) {
    //   padding: 6px;
    // }

    @media ${device.tablet} {
      width: 33.333% !important;
    }

    @media ${device.phone} {
      width: 50% !important;
      padding: 6px !important;
    }
  }
`

function WomenPage() {
  const womenCategories = [
    { category: "T-shirts", image: tshirt, url: "/dame/tshirts" },
    { category: "Skjorter", image: shirt, url: "/dame/skjorter" },
    { category: "Bukser", image: jeans, url: "/dame/bukser" },
    { category: "Trøjer", image: hoodie, url: "/dame/trojer" },
    { category: "Festtoppe", image: jacket, url: "/dame/festtoppe" },
    { category: "Tasker", image: bag, url: "/dame/tasker" },
    { category: "Sport", image: sport, url: "/dame/sport" },
    { category: "Sko", image: shoes, url: "/dame/sko" },
    { category: "Undertøj", image: underwear, url: "/dame/undertoj" },
    { category: "Accessories", image: accessories, url: "/dame/accessories" },
  ]

  return (
    <Layout title="Dametøj">
      <Breadcrumbs gender="dame" category="" />
      <Container>
        <Offers gender="women" />
        <StyledGrid>
          {womenCategories.map(e => (
            <Column
              key={e.url}
              className="product-card"
              mobile={8}
              tablet={5}
              computer={5}
            >
              <CategoryCard url={e.url} image={e.image} category={e.category} />
            </Column>
          ))}
        </StyledGrid>
      </Container>
    </Layout>
  )
}

export default WomenPage
